<template>
    <v-container :class="{'strikethrough-items':strikethroughItems}">
        <v-data-table
            v-if="typeof tableItems !== 'undefined'"
            class="transparent"
            :headers="headers"
            :items="tableItems"
            loader-height="10"
            :items-per-page=200
            :hide-default-footer="true"
            :sortable="true"
            sort-by="updated_on"
            sort-desc
            mobile-breakpoint="690"
        >
            <template v-slot:item.name="{ item }">
                <router-link :to="{ name: 'Wizard', params: {id: item.assetDTO.id}}" class="asset-link">
                    <div class="d-flex align-center">
                        <div class="d-flex align-center img-container mr-2 mt-1 mb-1">
                            <img
                                :src="item.assetDTO.thumbnail_82 || item.assetDTO.thumbnail_100 || item.assetDTO.thumbnail"
                                class="table-img"/>
                        </div>
                        {{ item.assetDTO.name }}
                        {{ item.amount > 1 ? '(' + item.amount + 'x)' : '' }}
                    </div>
                </router-link>
            </template>
            <template v-slot:item.type="{ item }">
                <span class="text-capitalize">{{ item.type.toLowerCase() }}</span>
            </template>
            <template v-slot:item.updated_on="{ item }">
                {{ item.updated_on | localDateTime }}
            </template>
            <template v-slot:item.price="{ item }">
                {{ item.price | localFormatNumber }} SCRL
            </template>
        </v-data-table>
    </v-container>
</template>

<script>

export default {
    components: {},
    props: {
        offers: Array,
        strikethroughItems: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            headers: [
                {text: 'Name', value: 'name', hide: 'smAndDown'},
                {text: 'Type', value: 'type', align: ' d-none d-md-table-cell'},
                {text: 'Date', value: 'updated_on'},
                {text: 'Price', value: 'price', cellClass: 'td-price'},
            ],
        }
    },
    mounted() {
    },
    methods: {},
    computed: {
        tableItems() {
            return this.offers || [];
        },
    },
}
</script>

<style lang="scss" scoped>
.asset-link {
    :hover {
        img {
            filter: drop-shadow(0px 0px 5px #fff);
        }
    }
}

.img-container {
    width: 50px;

    .table-img {
        width: auto;
        max-width: 50px;
        height: 50px;
    }
}
</style>

<style lang="scss">
.strikethrough-items .td-price {
    text-decoration: line-through !important;
}
</style>
