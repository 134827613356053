var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "6", xl: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: [_vm.rules.onlyNumber, _vm.rules.positiveNumber],
                      "background-color": "marketTab",
                      clearable: "",
                      color: "frames",
                      dense: "",
                      "hide-spin-buttons": "",
                      label: "min",
                      outlined: "",
                      solo: "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.valueChanged()
                      },
                    },
                    model: {
                      value: _vm.minPrice,
                      callback: function ($$v) {
                        _vm.minPrice = $$v
                      },
                      expression: "minPrice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    lg: "6",
                    offset: "0",
                    "offset-xl": "1",
                    xl: "4",
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: [_vm.rules.onlyNumber, _vm.rules.positiveNumber],
                      "background-color": "marketTab",
                      clearable: "",
                      color: "frames",
                      dense: "",
                      "hide-spin-buttons": "",
                      label: "max",
                      outlined: "",
                      solo: "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.valueChanged()
                      },
                    },
                    model: {
                      value: _vm.maxPrice,
                      callback: function ($$v) {
                        _vm.maxPrice = $$v
                      },
                      expression: "maxPrice",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "filter-title d-flex align-center justify-start mb-4" },
      [
        _c("span", { staticClass: "text-capitalize font-weight-bold mr-2" }, [
          _vm._v(" Price: "),
        ]),
        _c("img", {
          staticStyle: { width: "24px" },
          attrs: { src: "/img/scroll_48.png" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }