var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { class: { "strikethrough-items": _vm.strikethroughItems } },
    [
      typeof _vm.tableItems !== "undefined"
        ? _c("v-data-table", {
            staticClass: "transparent",
            attrs: {
              headers: _vm.headers,
              items: _vm.tableItems,
              "loader-height": "10",
              "items-per-page": 200,
              "hide-default-footer": true,
              sortable: true,
              "sort-by": "updated_on",
              "sort-desc": "",
              "mobile-breakpoint": "690",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.name",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "asset-link",
                          attrs: {
                            to: {
                              name: "Wizard",
                              params: { id: item.assetDTO.id },
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "d-flex align-center" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-center img-container mr-2 mt-1 mb-1",
                              },
                              [
                                _c("img", {
                                  staticClass: "table-img",
                                  attrs: {
                                    src:
                                      item.assetDTO.thumbnail_82 ||
                                      item.assetDTO.thumbnail_100 ||
                                      item.assetDTO.thumbnail,
                                  },
                                }),
                              ]
                            ),
                            _vm._v(
                              " " +
                                _vm._s(item.assetDTO.name) +
                                " " +
                                _vm._s(
                                  item.amount > 1
                                    ? "(" + item.amount + "x)"
                                    : ""
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "item.type",
                  fn: function ({ item }) {
                    return [
                      _c("span", { staticClass: "text-capitalize" }, [
                        _vm._v(_vm._s(item.type.toLowerCase())),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.updated_on",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("localDateTime")(item.updated_on)) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.price",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("localFormatNumber")(item.price)) +
                          " SCRL "
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              2274493058
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }