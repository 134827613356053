<template>
    <v-container>
        <v-tabs
            v-model="activeTab"
            background-color="transparent"
            center-active
            show-arrows
        >
            <v-tab>
                <span>Buy</span>
            </v-tab>
            <v-tab>
                <span>Sell</span>
            </v-tab>
            <v-tab>
                <span>Cancel</span>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab" class="transparent overflow-visible">
            <v-tab-item key="buy" :transition="false">
                <history-table :offers="buyTable"/>
            </v-tab-item>
            <v-tab-item key="sell" :transition="false">
                <history-table :offers="sellTable"/>
            </v-tab-item>
            <v-tab-item key="cancel" :transition="false">
                <history-table :offers="cancelTable" strikethrough-items/>
            </v-tab-item>
        </v-tabs-items>

    </v-container>
</template>

<script>

import HistoryTable from "@/components/market/history-table";

export default {
    components: {HistoryTable},
    props: {
        /** @type {AssetUnifiedData[]} */
        buyHistoryItems: Array,
        /** @type {AssetUnifiedData[]} */
        sellHistoryItems: Array
    },
    data() {
        return {
            activeTab: undefined,
        }
    },
    mounted() {
    },
    methods: {},
    computed: {
        buyTable() {
            const rows = this.buyHistoryItems?.map((row) => {
                return row.offer;
            });
            return rows || [];
        },
        sellHistoryParsedItems() {
            const rows = this.sellHistoryItems?.map((row) => {
                return row.offer;
            });
            return rows || [];
        },
        sellTable() {
            return this.sellHistoryParsedItems.filter((row) => {
                return row.status === 'TAKEN';
            });
        },
        cancelTable() {
            return this.sellHistoryParsedItems.filter((row) => {
                return ['USER_CANCELLED', 'ADMIN_CANCELLED'].includes(row.status);
            });
        },

    },
}
</script>

<style lang="scss" scoped>
::v-deep .v-tab {
    font-size: 80%;
    height: 40px;
    margin: 0 8px;
    padding: 0 40px !important;
}
</style>
