<template>
    <div>
        <div class="filter-title d-flex align-center justify-start mb-4">
            <span class="text-capitalize font-weight-bold mr-2">
                Price:
            </span>
            <img src="/img/scroll_48.png" style="width: 24px"/>
        </div>
        <v-container class="pa-0">
            <v-row dense>
                <v-col cols="12" lg="6" xl="4">
                    <v-text-field
                        v-model="minPrice"
                        :rules="[rules.onlyNumber, rules.positiveNumber]"
                        background-color="marketTab"
                        clearable
                        color="frames"
                        dense
                        hide-spin-buttons
                        label="min"
                        outlined
                        solo
                        @change="valueChanged()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" offset="0" offset-xl="1" xl="4">
                    <v-text-field
                        v-model="maxPrice"
                        :rules="[rules.onlyNumber, rules.positiveNumber]"
                        background-color="marketTab"
                        clearable
                        color="frames"
                        dense
                        hide-spin-buttons
                        label="max"
                        outlined
                        solo
                        @change="valueChanged()"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            maxPrice: undefined,
            minPrice: undefined,
            rules: {
                onlyNumber: value => !isNaN(value || 0) || "Price have to be number.",
                positiveNumber: value => (value || 0) >= 0 || "Price can't be negative.",
            }
        }
    },
    methods: {

        valueChanged() {
            this.maxPrice = Number(this.maxPrice) || undefined;
            this.minPrice = Number(this.minPrice) || undefined;
            this.$emit('value-changed',
                {
                    minPrice: this.minPrice,
                    maxPrice: this.maxPrice
                }
            )
        },
    }
}
</script>

<style lang="scss" scoped>
.filter-title {
    color: var(--v-frames-base);
}
</style>
