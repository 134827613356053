<template>
    <div>
        <p class="text-uppercase font-weight-bold">Category:</p>
        <!--        ToDo: for multiple select: https://codepen.io/johnjleider/pen/RBKgwd -->
        <v-radio-group
            v-model="selectedCategory"
            @change="valueChanged()"
            column
            mandatory
        >
            <v-radio
                v-for="category in availableCategories"
                :label="category.toLocaleLowerCase()"
                :value="[category]"
                class="text-capitalize"
                color="frames"
            ></v-radio>
        </v-radio-group>
    </div>
</template>

<script>
import {AssetType} from "@/classes/asset/AssetType";

const ALL = 'ALL';
export default {
    data() {
        return {
            availableCategories: [
                ALL,
                AssetType.WIZARD,
                AssetType.LAND,
                AssetType.ENHANCEMENT,
            ],
            selectedCategory: []
        }
    },
    methods: {
        valueChanged() {
            let selCategories = this.selectedCategory;
            if (ALL === this.selectedCategory[0]) {
                selCategories = this.availableCategories;
            }
            this.$emit('value-changed', {assetTypes: selCategories})
        },
    }
}
</script>

<style scoped>

</style>
