<template>
    <div>
        <div class="filter-title d-flex align-center justify-start mb-4">
            <span class="text-capitalize font-weight-bold mr-2">
                Sort by:
            </span>
        </div>
        <v-container class="pa-0">
            <v-row dense>
                <v-col cols="12" xl="7">
                    <v-select
                        v-model="selectedField"
                        :items="sortableFields"
                        background-color="marketTab"
                        color="frames"
                        dense
                        item-color="frames"
                        item-text="name"
                        item-value="path"
                        outlined
                        single-line
                        solo
                        @change="valueChanged"
                    ></v-select>
                </v-col>
                <v-col class="d-flex flex-column align-start align-lg-center" cols="12" xl="5">
                    <v-select
                        v-model="selectedOrder"
                        :items="sortOrder"
                        background-color="marketTab"
                        color="frames"
                        dense
                        item-color="frames"
                        item-text="name"
                        item-value="value"
                        outlined
                        single-line
                        solo
                        @change="valueChanged"
                        class="w-100"
                    ></v-select>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            sortableFields: [
                {name: 'Date', path: "offer.id"},
                {name: 'Price', path: "offer.price"},
                {name: 'Amount', path: "offer.amount"},
                {name: 'Asset Id', path: "data.id"},
            ],
            sortOrder: [
                {name: 'Ascending', value: 'asc'},
                {name: 'Descending', value: 'desc'},
            ],
            selectedField: "offer.id",
            selectedOrder: "desc",
        }
    },
    computed: {
        offerSorting() {
            return (
                {
                    selectedField: this.selectedField,
                    selectedOrder: this.selectedOrder,
                }
            )
        },
    },
    methods: {
        valueChanged() {
            this.$emit('value-changed',
                {
                    offerSorting: this.offerSorting,
                }
            )
        },
    }
}
</script>

<style lang="scss" scoped>
.filter-title {
    color: var(--v-frames-base);
}
</style>
