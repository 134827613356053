var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-fade-transition",
    { attrs: { group: "", "hide-on-leave": "" } },
    [
      _vm.isAssetSelected
        ? _c(
            "div",
            {
              key: "full-asset-container",
              staticClass: "full-asset-container",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "buttons-navigation w-100 mb-4 d-flex align-center justify-space-between",
                },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.selectPreviousAsset()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-arrow-left"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goBackToAssets()
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v("All")]),
                      _c(
                        "v-icon",
                        { staticClass: "ml-2", attrs: { small: "" } },
                        [_vm._v("mdi-view-list")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.selectNextAsset()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-arrow-right"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-slide-y-transition",
                [
                  _vm.displayFullAsset &&
                  _vm.selectedAssetType === _vm.assetTypeEnum.WIZARD
                    ? _c("full-wizard", {
                        attrs: {
                          "active-offer": _vm.selectedAssetOffer,
                          "is-owner": _vm.isOwnerOfSelectedAsset,
                          wizard: _vm.selectedAssetDTO,
                        },
                      })
                    : _vm.displayFullAsset &&
                      _vm.selectedAssetType === _vm.assetTypeEnum.LAND
                    ? _c("full-land", {
                        attrs: {
                          "active-offer": _vm.selectedAssetOffer,
                          "is-owner": _vm.isOwnerOfSelectedAsset,
                          item: _vm.selectedAssetDTO,
                        },
                      })
                    : _vm.displayFullAsset &&
                      _vm.selectedAssetType === _vm.assetTypeEnum.ENHANCEMENT
                    ? _c("full-enhancement", {
                        attrs: {
                          "active-offer": _vm.selectedAssetOffer,
                          amount: _vm.chooseAmount(
                            _vm.selectedAssetOffer,
                            _vm.selectedAssetDTO
                          ),
                          item: _vm.selectedAssetDTO,
                          "wallet-address": _vm.walletAddress,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              key: "asset-browser",
              staticClass: "d-flex flex-column align-stretch justify-start",
            },
            [
              _c(
                "v-fade-transition",
                {
                  staticClass:
                    "mini-assets-container d-flex flex-row flex-wrap align-cap justify-center",
                  attrs: { group: "" },
                },
                _vm._l(_vm.content, function (asset, i) {
                  return i >= _vm.firstPaginatedIndex &&
                    i < _vm.lastPaginatedIndex &&
                    _vm.displayMiniAssets
                    ? _c(
                        "div",
                        {
                          key: "assetNo_" + asset.data.type + i,
                          staticClass: "mini-asset-composition px-2 py-2",
                          attrs: { id: _vm.getAssetId(i, asset.data) },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mini-asset",
                              on: {
                                click: function ($event) {
                                  return _vm.selectAsset(i)
                                },
                              },
                            },
                            [
                              asset.data.type === _vm.assetTypeEnum.WIZARD
                                ? _c("mini-wizard", {
                                    attrs: { item: asset.data },
                                  })
                                : asset.data.type === _vm.assetTypeEnum.LAND
                                ? _c("mini-land", {
                                    attrs: { item: asset.data },
                                  })
                                : asset.data.type ===
                                  _vm.assetTypeEnum.ENHANCEMENT
                                ? _c("mini-enhancement", {
                                    attrs: {
                                      amount: _vm.chooseAmount(asset),
                                      item: asset.data,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          asset.offer
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "asset-quick-info mx-4 pt-2 pb-1 d-flex align-center justify-center",
                                  class: {
                                    "text-decoration-line-through": [
                                      _vm.offerStatuses.USER_CANCELLED,
                                      _vm.offerStatuses.ADMIN_CANCELLED,
                                    ].includes(asset.offer.status),
                                    "grey--text":
                                      asset.offer.status ===
                                      _vm.offerStatuses.USER_CANCELLED,
                                    "red--text":
                                      asset.offer.status ===
                                      _vm.offerStatuses.ADMIN_CANCELLED,
                                  },
                                  attrs: { "data-offer-id": asset.offer.id },
                                },
                                [
                                  _c("img", {
                                    staticClass: "d-inline-block",
                                    staticStyle: { width: "16px" },
                                    attrs: { src: "/img/scroll_48.png" },
                                  }),
                                  _c("span", { staticClass: "caption" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("localFormatNumber")(
                                          Number(asset.offer.price)
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
              _vm.paginationLength > 1
                ? _c(
                    "v-container",
                    { staticClass: "pagination-container" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-pagination", {
                                attrs: { length: _vm.paginationLength },
                                on: { input: _vm.rerenderMiniAssets },
                                model: {
                                  value: _vm.selectedPage,
                                  callback: function ($$v) {
                                    _vm.selectedPage = $$v
                                  },
                                  expression: "selectedPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }