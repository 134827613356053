var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "page-filters-section",
        {
          staticClass: "filters-underline",
          attrs: {
            overlay: false,
            "top-img": "/img/market/marketplace-header.png",
          },
        },
        [
          _c("div", { staticClass: "shadow" }),
          _c(
            "v-container",
            [
              _c("v-row", { attrs: { justify: "center", "no-gutters": "" } }, [
                _c("h1", { staticClass: "text-center" }, [
                  _vm._v("Marketplace"),
                ]),
              ]),
              _c(
                "v-row",
                { staticClass: "mt-10", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "d-md-flex", attrs: { cols: "1", md: "3" } },
                    [
                      _c("v-text-field", {
                        staticClass: "d-none d-md-flex",
                        attrs: {
                          "append-icon": "mdi-magnify",
                          "background-color": "buttonBg",
                          label: "Search",
                          color: "frames",
                          clearable: "",
                          dense: "",
                          solo: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.searchText,
                          callback: function ($$v) {
                            _vm.searchText = $$v
                          },
                          expression: "searchText",
                        },
                      }),
                      _c(
                        "v-app-bar-nav-icon",
                        {
                          staticClass:
                            "d-flex d-md-none filters-button rounded-t-xl rounded-b-0 mx-0 px-2 px-sm-5 pt-3",
                          on: {
                            click: function ($event) {
                              _vm.drawer = !_vm.drawer
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-filter")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "11", md: "9" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            grow: "",
                            "background-color": "transparent",
                            "center-active": "",
                            "show-arrows": "",
                          },
                          model: {
                            value: _vm.browsedTab,
                            callback: function ($$v) {
                              _vm.browsedTab = $$v
                            },
                            expression: "browsedTab",
                          },
                        },
                        [
                          _c("v-tabs-slider", {
                            attrs: { color: "transparent" },
                          }),
                          _vm._l(_vm.tabsData, function (tab, i) {
                            return _c(
                              "v-tab",
                              { key: `tab_${i}_${tab.name}` },
                              [
                                _c("span", [_vm._v(_vm._s(tab.name))]),
                                tab.icon
                                  ? _c(
                                      "v-icon",
                                      {
                                        staticClass: "ml-1",
                                        attrs: { small: "" },
                                      },
                                      [_vm._v(_vm._s(tab.icon))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "page-content", attrs: { dense: "" } },
        [
          _c(
            "v-navigation-drawer",
            {
              staticClass:
                "d-flex d-md-none align-stretch navigation-drawer-custom",
              attrs: {
                color: "background",
                app: "",
                right: "",
                temporary: "",
                width: "250px",
              },
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _c("v-text-field", {
                staticClass: "px-4 pt-6 pb-0",
                attrs: {
                  "append-icon": "mdi-magnify",
                  "background-color": "buttonBg",
                  clearable: "",
                  color: "frames",
                  dense: "",
                  label: "Search",
                  outlined: "",
                  solo: "",
                },
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText",
                },
              }),
              _c("marketplace-filters-composition", {
                on: {
                  "filters-changed": _vm.handleFiltersChange,
                  "sorting-changed": _vm.handleSortingChange,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-none d-md-flex", attrs: { cols: "3" } },
            [
              _c(
                "div",
                { staticClass: "filters-border d-flex align-stretch" },
                [
                  _c(
                    "div",
                    { staticClass: "filters-container" },
                    [
                      _c("marketplace-filters-composition", {
                        on: {
                          "filters-changed": _vm.handleFiltersChange,
                          "sorting-changed": _vm.handleSortingChange,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "9" } },
            [
              _c(
                "v-tabs-items",
                {
                  staticClass: "transparent overflow-visible",
                  model: {
                    value: _vm.browsedTab,
                    callback: function ($$v) {
                      _vm.browsedTab = $$v
                    },
                    expression: "browsedTab",
                  },
                },
                _vm._l(_vm.tabsData, function (tab, i) {
                  return _c(
                    "v-tab-item",
                    { key: `tabItem_${i}_${tab.name}` },
                    [
                      _c(
                        "v-overlay",
                        { attrs: { value: _vm.loadingOffers, absolute: "" } },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", size: "100" },
                          }),
                        ],
                        1
                      ),
                      !_vm.loadingOffers && 0 === tab.offers.length
                        ? _c("h3", { staticClass: "text-center mt-15" }, [
                            _vm._v(" - No results - "),
                          ])
                        : _vm._e(),
                      !_vm.loadingOffers && tab.offers.length > 0 && !tab.type
                        ? _c("asset-browser", {
                            attrs: { content: tab.offers },
                          })
                        : _vm._e(),
                      !_vm.loadingOffers && tab.offers.length > 0 && tab.type
                        ? _c("market-history", {
                            attrs: {
                              buyHistoryItems: tab.buyHistoryOffers,
                              sellHistoryItems: tab.sellHistoryOffers,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }