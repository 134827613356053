<template>
    <v-fade-transition group hide-on-leave>
        <div v-if="isAssetSelected" :key="'full-asset-container'" class="full-asset-container">
            <div class="buttons-navigation w-100 mb-4 d-flex align-center justify-space-between">
                <v-btn @click="selectPreviousAsset()">
                    <v-icon small>mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn @click="goBackToAssets()">
                    <span>All</span>
                    <v-icon class="ml-2" small>mdi-view-list</v-icon>
                </v-btn>
                <v-btn @click="selectNextAsset()">
                    <v-icon small>mdi-arrow-right</v-icon>
                </v-btn>
            </div>
            <v-slide-y-transition>
                <full-wizard v-if="displayFullAsset && selectedAssetType === assetTypeEnum.WIZARD"
                             :active-offer="selectedAssetOffer"
                             :is-owner="isOwnerOfSelectedAsset"
                             :wizard="selectedAssetDTO"
                ></full-wizard>
                <full-land v-else-if="displayFullAsset && selectedAssetType === assetTypeEnum.LAND"
                           :active-offer="selectedAssetOffer"
                           :is-owner="isOwnerOfSelectedAsset"
                           :item="selectedAssetDTO"
                ></full-land>
                <full-enhancement v-else-if="displayFullAsset && selectedAssetType === assetTypeEnum.ENHANCEMENT"
                                  :active-offer="selectedAssetOffer"
                                  :amount="chooseAmount(selectedAssetOffer, selectedAssetDTO)"
                                  :item="selectedAssetDTO"
                                  :wallet-address="walletAddress"
                ></full-enhancement>
            </v-slide-y-transition>
        </div>
        <div v-else key="asset-browser" class="d-flex flex-column align-stretch justify-start">
            <v-fade-transition
                class="mini-assets-container d-flex flex-row flex-wrap align-cap justify-center"
                group
            >
                <div v-for="(asset, i) in content"
                     v-if="i >= firstPaginatedIndex && i < lastPaginatedIndex && displayMiniAssets"
                     :id="getAssetId(i, asset.data)"
                     :key="'assetNo_'+asset.data.type+i"
                     class="mini-asset-composition px-2 py-2">
                    <div class="mini-asset"
                         @click="selectAsset(i)">
                        <mini-wizard
                            v-if="asset.data.type === assetTypeEnum.WIZARD"
                            :item="asset.data"
                        />
                        <mini-land
                            v-else-if="asset.data.type === assetTypeEnum.LAND"
                            :item="asset.data"
                        />
                        <mini-enhancement
                            v-else-if="asset.data.type === assetTypeEnum.ENHANCEMENT"
                            :amount="chooseAmount(asset)"
                            :item="asset.data"
                        />
                    </div>
                    <div v-if="asset.offer"
                         :data-offer-id="asset.offer.id"
                         :class="{
                        'text-decoration-line-through': [offerStatuses.USER_CANCELLED, offerStatuses.ADMIN_CANCELLED].includes(asset.offer.status),
                        'grey--text': asset.offer.status === offerStatuses.USER_CANCELLED,
                        'red--text': asset.offer.status === offerStatuses.ADMIN_CANCELLED,
                    }"
                         class="asset-quick-info mx-4 pt-2 pb-1 d-flex align-center justify-center">
                        <img class="d-inline-block" src="/img/scroll_48.png" style="width: 16px;">
                        <span class="caption">{{ Number(asset.offer.price) | localFormatNumber }}</span>
                    </div>
                </div>
            </v-fade-transition>
            <v-container v-if="paginationLength > 1" class="pagination-container">
                <v-row>
                    <v-col cols="12">
                        <v-pagination v-model="selectedPage" :length="paginationLength"
                                      @input="rerenderMiniAssets"></v-pagination>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </v-fade-transition>
</template>

<script>
import {AssetType} from "@/classes/asset/AssetType";
import MiniWizard from "@/components/assets/wizards/mini-wizard";
import MiniLand from "@/components/assets/lands/mini-land";
import MiniEnhancement from "@/components/assets/enhancements/mini-enhancement";
import {OfferStatus} from "@/classes/market/OfferStatus";
import FullLand from "@/components/assets/lands/full-land";
import FullWizard from "@/components/assets/wizards/full-wizard";
import AssetService from "@/services/assetService";
import goTo from "vuetify/lib/services/goto";
import FullEnhancement from "@/components/assets/enhancements/full-enhancement";

export default {
    components: {FullEnhancement, FullWizard, FullLand, MiniEnhancement, MiniLand, MiniWizard},
    props: {
        /**
         * @type {AssetUnifiedData[]}
         */
        content: Array
    },
    data() {
        return {
            assetService: undefined,
            assetTypeEnum: AssetType,
            offerStatuses: OfferStatus,
            selectedAssetIndex: null,
            selectedPagination: 14,
            selectedPage: 1,
            displayFullAsset: true,
            displayMiniAssets: true,
        };
    },
    mounted() {
        if (this.isWalletConnected) {
            this.init();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.init();
            });
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        walletAddress() {
            return this.web3.address?.toLowerCase();
        },
        paginationLength() {
            return Math.ceil(this.content.length / this.selectedPagination);
        },
        firstPaginatedIndex() {
            return this.selectedPagination * (this.selectedPage - 1);
        },
        lastPaginatedIndex() {
            return this.firstPaginatedIndex + this.selectedPagination;
        },
        isAssetSelected() {
            return !!this.selectedAssetDTO;
        },
        selectedAssetDTO() {
            return this.content[this.selectedAssetIndex]?.data;
        },
        selectedAssetOffer() {
            return this.content[this.selectedAssetIndex]?.offer;
        },
        selectedAssetType() {
            return this.selectedAssetDTO.type;
        },
        isOwnerOfSelectedAsset() {
            return Boolean(
                this.isWalletConnected
                &&
                (
                    this.selectedAssetDTO.owner?.toLowerCase() === this.web3.address?.toLowerCase()
                    ||
                    (
                        this.selectedAssetOffer?.status === OfferStatus.AVAILABLE
                        &&
                        this.selectedAssetOffer?.creator.toLowerCase() === this.web3.address?.toLowerCase()
                    )
                )
            )
        },
        selectedLastAsset() {
            return (this.selectedAssetIndex + 1) >= this.content.length;
        },
        selectedFirstAsset() {
            return this.selectedAssetIndex <= 0;
        },
    },
    methods: {
        init() {
            this.assetService = new AssetService(this.web3, this.flashMessage)
        },
        selectAsset(index) {
            this.selectedAssetIndex = index;
            this.goToAssetHeader();
        },
        selectNextAsset() {
            if (this.selectedLastAsset) {
                this.selectedAssetIndex = 0;
            } else {
                this.selectedAssetIndex++
            }
            this.rerenderSelectedAsset();
        },
        selectPreviousAsset() {
            if (this.selectedFirstAsset) {
                this.selectedAssetIndex = this.content.length - 1;
            } else {
                this.selectedAssetIndex--
            }
            this.rerenderSelectedAsset();
        },
        goBackToAssets() {
            this.selectedPage = Math.floor(this.selectedAssetIndex / this.selectedPagination) + 1;

            const pastIndex = this.selectedAssetIndex;
            const pastAssetDTO = this.selectedAssetDTO;

            this.selectedAssetIndex = null;

            this.goToAssetTile(pastIndex, pastAssetDTO);
        },
        getAssetId(index, asset) {
            return `asset_${index}_${asset.type}_${asset.id}`;
        },
        goToAssetHeader() {
            //scrolls UI after time to render
            setTimeout(() => goTo('.full-asset-container', {offset: 15}), 10);
        },
        goToAssetTile(index, asset) {
            //scrolls UI after time to render
            const assetId = this.getAssetId(index, asset);
            setTimeout(() => goTo(`#${assetId}`, {offset: 0}), 10);
        },
        chooseAmount(fullAsset, assetData) {
            return Number(fullAsset?.offer?.amount || assetData?.amount);
        },
        resetBrowsing() {
            this.selectedAssetIndex = null;
            this.selectedPage = 1;
            this.goToTop();
        },
        goToTop() {
            //scrolls UI after time to render
            setTimeout(() => goTo(`.v-application`), 10);
        },
        rerenderSelectedAsset() {
            this.displayFullAsset = false;
            setTimeout(() => this.displayFullAsset = true, 325);
        },
        rerenderMiniAssets() {
            this.displayMiniAssets = false;
            setTimeout(() => this.displayMiniAssets = true, 325);
        },
    },
    watch: {
        content() {
            this.resetBrowsing()
        },
    }
}
</script>

<style lang="scss" scoped>

.mini-asset-composition {
    cursor: pointer;
}

.asset-quick-info {
    margin-top: -5px;
    background-image: url("/img/market/price-plate.png");
    background-size: 100% 100%;
}

.full-asset-container {
}

.mini-assets-container {
}

::v-deep .mini-asset-composition:hover {
    .mini-wizard, .mini-enhancement {
        .item-box {
            @include bg-card_universal-hover;

            .item-badge {
                @include bg-card_badge-hover;
            }
        }
    }

    .mini-land {
        &.mini-land-rarity-rare {
            .item-box {
                @include bg-card-rare-hover;
            }
        }

        &.mini-land-rarity-epic {
            .item-box {
                @include bg-card_epic-hover;
            }
        }

        .item-box {
            @include bg-card_normal-hover;
        }
    }


    .asset-quick-info {
        background-image: url("/img/market/price-plate-hover.png");
    }
}
</style>
