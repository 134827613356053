import { render, staticRenderFns } from "./marketplace-filters-composition.vue?vue&type=template&id=15441313&scoped=true"
import script from "./marketplace-filters-composition.vue?vue&type=script&lang=js"
export * from "./marketplace-filters-composition.vue?vue&type=script&lang=js"
import style0 from "./marketplace-filters-composition.vue?vue&type=style&index=0&id=15441313&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15441313",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/wizarre-app-fe/wizarre-app-fe/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15441313')) {
      api.createRecord('15441313', component.options)
    } else {
      api.reload('15441313', component.options)
    }
    module.hot.accept("./marketplace-filters-composition.vue?vue&type=template&id=15441313&scoped=true", function () {
      api.rerender('15441313', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/market/marketplace-filters-composition.vue"
export default component.exports