import AssetDTOWithType from "@/classes/asset/AssetDTOWithType";

class AssetUnifiedData {
    /**
     * @type {AssetDTOWithType}
     */
    data;

    /**
     * @type {OfferDTO}
     */
    offer;

    /**
     *
     * @param data {AssetDTOWithType}
     * @param offer {OfferDTO}
     */
    constructor(data, offer) {
        this.data = {
            ...data,
            id: Number(data.id),
            created_on: new Date(data.created_on),
        };
        this.offer = offer ? {
            ...offer,
            id: Number(offer.id),
            price: Number(offer.price),
            amount: Number(offer.amount),
            created_on: new Date(offer.created_on),
            updated_on: new Date(offer.updated_on),
        } : undefined;
    }
}

export default class AssetUnifiedDataBuilder {
    /**
     *
     * @param asset {AssetDTOWithType}
     */
    static buildFromAssetDTOWithType(asset) {
        return new AssetUnifiedData(asset, undefined);
    }

    /**
     *
     * @param offer {OfferDTO}
     */
    static buildFromOfferDTO(offer) {
        const assetDTOWithType = new AssetDTOWithType(offer.assetDTO, offer.type);
        return new AssetUnifiedData(assetDTOWithType, offer);
    }
}

