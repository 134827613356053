var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-tabs",
        {
          attrs: {
            "background-color": "transparent",
            "center-active": "",
            "show-arrows": "",
          },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c("v-tab", [_c("span", [_vm._v("Buy")])]),
          _c("v-tab", [_c("span", [_vm._v("Sell")])]),
          _c("v-tab", [_c("span", [_vm._v("Cancel")])]),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          staticClass: "transparent overflow-visible",
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "v-tab-item",
            { key: "buy", attrs: { transition: false } },
            [_c("history-table", { attrs: { offers: _vm.buyTable } })],
            1
          ),
          _c(
            "v-tab-item",
            { key: "sell", attrs: { transition: false } },
            [_c("history-table", { attrs: { offers: _vm.sellTable } })],
            1
          ),
          _c(
            "v-tab-item",
            { key: "cancel", attrs: { transition: false } },
            [
              _c("history-table", {
                attrs: { offers: _vm.cancelTable, "strikethrough-items": "" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }