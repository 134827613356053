var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("Category:"),
      ]),
      _c(
        "v-radio-group",
        {
          attrs: { column: "", mandatory: "" },
          on: {
            change: function ($event) {
              return _vm.valueChanged()
            },
          },
          model: {
            value: _vm.selectedCategory,
            callback: function ($$v) {
              _vm.selectedCategory = $$v
            },
            expression: "selectedCategory",
          },
        },
        _vm._l(_vm.availableCategories, function (category) {
          return _c("v-radio", {
            staticClass: "text-capitalize",
            attrs: {
              label: category.toLocaleLowerCase(),
              value: [category],
              color: "frames",
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }