<template>
    <v-container>
        <page-filters-section
            :overlay="false" class="filters-underline"
            top-img="/img/market/marketplace-header.png"
        >
            <div class="shadow"/>
            <v-container>
                <v-row justify="center" no-gutters>
                    <h1 class="text-center">Marketplace</h1>
                </v-row>
                <v-row class="mt-10" dense>
                    <v-col class="d-md-flex" cols="1" md="3">
                        <v-text-field
                            v-model="searchText"
                            append-icon="mdi-magnify"
                            background-color="buttonBg"
                            label="Search"
                            color="frames"
                            clearable
                            dense
                            solo
                            outlined
                            class="d-none d-md-flex"
                        ></v-text-field>
                        <v-app-bar-nav-icon
                            class="d-flex d-md-none filters-button rounded-t-xl rounded-b-0 mx-0 px-2 px-sm-5 pt-3"
                            @click="drawer = !drawer"
                        >
                            <v-icon>mdi-filter</v-icon>
                        </v-app-bar-nav-icon>
                    </v-col>
                    <v-col cols="11" md="9">
                        <v-tabs
                            v-model="browsedTab"
                            grow
                            background-color="transparent"
                            center-active
                            show-arrows
                        >
                            <v-tabs-slider color="transparent"></v-tabs-slider>
                            <v-tab v-for="(tab, i) in tabsData" :key="`tab_${i}_${tab.name}`">
                                <span>{{ tab.name }}</span>
                                <v-icon v-if="tab.icon" class="ml-1" small>{{ tab.icon }}</v-icon>
                            </v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-container>
        </page-filters-section>

        <v-row class="page-content" dense>
            <v-navigation-drawer
                v-model="drawer"
                class="d-flex d-md-none align-stretch navigation-drawer-custom"
                color="background"
                app
                right
                temporary
                width="250px"
            >
                <v-text-field
                    v-model="searchText"
                    append-icon="mdi-magnify"
                    background-color="buttonBg"
                    class="px-4 pt-6 pb-0"
                    clearable
                    color="frames"
                    dense
                    label="Search"
                    outlined
                    solo
                ></v-text-field>
                <marketplace-filters-composition
                    @filters-changed="handleFiltersChange"
                    @sorting-changed="handleSortingChange"
                >
                </marketplace-filters-composition>
            </v-navigation-drawer>
            <v-col class="d-none d-md-flex" cols="3">
                <div class="filters-border d-flex align-stretch">
                    <div class="filters-container">
                        <marketplace-filters-composition
                            @filters-changed="handleFiltersChange"
                            @sorting-changed="handleSortingChange"
                        >
                        </marketplace-filters-composition>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="9">
                <v-tabs-items v-model="browsedTab" class="transparent overflow-visible">
                    <v-tab-item v-for="(tab, i) in tabsData" :key="`tabItem_${i}_${tab.name}`">
                        <v-overlay
                            :value="loadingOffers"
                            absolute
                        >
                            <v-progress-circular
                                indeterminate
                                size="100"
                            ></v-progress-circular>
                        </v-overlay>

                        <h3 v-if="!loadingOffers && 0 === tab.offers.length" class="text-center mt-15">
                            - No results -
                        </h3>
                        <asset-browser
                            v-if="!loadingOffers && tab.offers.length > 0 && !tab.type"
                            :content="tab.offers"
                        />
                        <market-history
                            v-if="!loadingOffers && tab.offers.length > 0 && tab.type"
                            :buyHistoryItems="tab.buyHistoryOffers"
                            :sellHistoryItems="tab.sellHistoryOffers"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PageFiltersSection from "../components/page-filters-section";
import AssetBrowser from "@/components/assets/asset-browser";
import MarketService from "@/services/marketService";
import AssetService from "@/services/assetService";
import AssetUnifiedDataBuilder from "@/classes/asset/AssetUnifiedData";
import {OfferStatus} from "@/classes/market/OfferStatus";
import MarketplaceFiltersComposition from "@/components/market/marketplace-filters-composition";
import OfferApiFiltersDTO from "@/classes/filters/OfferApiFiltersDTO";
import MarketHistory from "@/components/market/market-history";

export default {
    components: {MarketHistory, MarketplaceFiltersComposition, AssetBrowser, PageFiltersSection},

    data() {
        return {
            searchText: this.$route?.params?.search?.trim() || '',
            assetService: undefined,
            marketService: undefined,
            browsedTab: undefined,
            allOpenOffers: [],
            offerAbleAssets: [],
            yourOffers: [],
            yourBuyHistoryOffers: [],
            offerFilters: new OfferApiFiltersDTO(),
            offerSorting: {
                "selectedField": "offer.updated_on",
                "selectedOrder": "desc"
            },
            drawer: false,
            loadingOffers: false,
        };
    },

    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        walletAddress() {
            return this.web3.address;
        },
        tabsData() {
            const offerAbleAssets = this.getFilteredAndSortedAssets(this.offerAbleAssets, this.offerSorting);

            const yourOpenOffers = this.getFilteredAndSortedAssets(
                this.yourOffers.filter(data => data.offer.status === OfferStatus.AVAILABLE),
                this.offerSorting
            );

            const buyHistoryOffers = this.getFilteredAndSortedAssets(
                this.yourBuyHistoryOffers,
                this.offerSorting
            );

            const sellHistoryOffers = this.getFilteredAndSortedAssets(
                this.yourOffers.filter(data => data.offer.status !== OfferStatus.AVAILABLE),
                this.offerSorting
            );

            const commonOpenOffers = this.getFilteredAndSortedAssets(
                this.allOpenOffers
                // .filter(asset => !yourOpenOffers.map(asset => asset.offer.id).includes(asset.offer.id))
                ,
                this.offerSorting
            );

            if (this.isWalletConnected) {
                return [
                    {name: 'buy', offers: commonOpenOffers},
                    // {name: 'wished', offers: wished, icon: 'mdi-star'},
                    {name: 'sell', offers: offerAbleAssets},
                    {name: 'your offers', offers: yourOpenOffers},
                    {
                        name: 'your history',
                        offers: (buyHistoryOffers + sellHistoryOffers),
                        buyHistoryOffers,
                        sellHistoryOffers,
                        type: 'table'
                    },
                ];
            } else {
                return [
                    {name: 'buy', offers: commonOpenOffers},
                    // {name: 'wished', offers: wished, icon: 'mdi-star'},
                ]
            }
        },
    },
    mounted: function () {
        this.marketService = new MarketService();

        this.fetchCommonOffers();

        if (this.isWalletConnected) {
            this.init();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.init();
            });
        }
        window.addEventListener("reload-view", this.refresh);
    },
    destroyed() {
        window.removeEventListener("reload-view", this.refresh);
    },
    methods: {
        refresh() {
            if (!this.marketService) {
                return;
            }
            this.fetchCommonOffers();
            if (this.isWalletConnected) {
                this.fetchMyOffers();
            }
            this.$forceUpdate();
        },
        init() {
            this.assetService = new AssetService(this.web3, this.flashMessage)
            this.fetchMyOffers();
        },
        fetchCommonOffers() {
            this.loadingOffers = true;
            this.allOpenOffers = [];
            this.marketService
                .getAllOpenOffers(this.offerFilters)
                .then(value => {
                    this.allOpenOffers = value
                        .map(offer => AssetUnifiedDataBuilder.buildFromOfferDTO(offer));
                    setTimeout(() => this.loadingOffers = false, 500)
                });
        },
        fetchMyOffers() {
            this.marketService
                .getAllOffersFrom(this.walletAddress, this.offerFilters)
                .then(value => {
                    this.yourOffers = value
                        .map(offer => AssetUnifiedDataBuilder.buildFromOfferDTO(offer));
                });

            this.marketService
                .getAllOffersTakenBy(this.walletAddress, this.offerFilters)
                .then(value => {
                    this.yourBuyHistoryOffers = value
                        .map(offer => AssetUnifiedDataBuilder.buildFromOfferDTO(offer));
                });

            this.assetService.fetchMyAssets(this.offerFilters.assetTypes)
                .then(value => {
                    this.offerAbleAssets = value
                        .map(asset => AssetUnifiedDataBuilder.buildFromAssetDTOWithType(asset));
                });
        },
        assetContainsText(asset, text) {
            if (!(text?.length > 0)) {
                return true;
            }
            const searchableValues = [
                asset?.offer?.price,
                asset?.offer?.amount,
                asset?.data?.id,
                asset?.data?.name,
                asset?.data?.type,
                asset?.data?.description,
                asset?.data?.attributes,
                asset?.data?.wizarre?.spells,
                asset?.data?.wizarre?.typeName,
                asset?.data?.wizarre?.rarity,
            ].filter(value => !!value)

            const result = JSON.stringify(searchableValues).toLowerCase().search(text.toLowerCase());
            return result >= 0;
        },
        handleFiltersChange(args) {
            this.offerFilters = args.offerFilters;
            this.refresh();
        },
        handleSortingChange(args) {
            this.offerSorting = args.offerSorting;
        },
        getFilteredAndSortedAssets(assetsArray, sortProperties) {
            const filteredAssets = assetsArray
                .filter(asset => this.assetContainsText(asset, this.searchText));
            const sortedAscending = _.sortBy(filteredAssets, [sortProperties.selectedField]);
            const isSortDescending = sortProperties.selectedOrder === 'desc';
            return isSortDescending ? _.reverse(sortedAscending) : sortedAscending;
        },
    }
};

</script>

<style lang="scss" scoped>
::v-deep .v-tab {
    color: var(--v-frames-base) !important;
    background-color: var(--v-marketTab-base);
    border-radius: 15px 15px 0 0;
    border: 2.5px solid var(--v-frames-base);
    border-bottom: 0 transparent;
    margin: 0 8px;
    padding: 0 4px;
    transition-duration: 250ms;
    transition-property: padding, background-color, color;
}

::v-deep .v-tab--active {
    background-color: var(--v-marketTab-lighten2);
    color: var(--v-golden-base) !important;
    font-weight: bold;
    padding: 0 8px;
}

::v-deep .buttons-navigation {
    .theme--dark.v-btn.v-btn--has-bg {
        background-color: var(--v-marketTab-base) !important;
    }
}

.filters-underline {
    overflow: hidden;
    border-bottom: 3px solid var(--v-frames-base);

    .shadow {
        z-index: 10;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0px -10px 15px -10px rgba(0, 0, 0, 1);
    }
}


.page-content {
}

.filters-border {
    margin-top: -16px;
    padding-top: 16px;
    width: 100%;
    min-height: 100%;
    box-shadow: calc(-100vmax + 4px) 100vh 0px 100vmax var(--v-marketBg-darken1);
}

.filters-container {
    width: 100%;
    min-height: 100%;
    background-color: var(--v-marketBg-base);
    box-shadow: -100vmax 100vh 0px 100vmax rgba(28, 39, 47, 1);
}

.filters-button {
    border: 3px solid var(--v-frames-base);

}

.navigation-drawer-custom {
    overflow-y: auto;
    margin-top: 56px;
}
</style>
