<template>
    <v-container>
        <v-row>
            <v-col>
                <asset-category-filter @value-changed="handleCategoryChange"></asset-category-filter>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <asset-sort-chooser @value-changed="handleSortChange"></asset-sort-chooser>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <asset-price-filter @value-changed="handlePriceChange"></asset-price-filter>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AssetCategoryFilter from "@/components/market/filters/asset-category-filter";
import OfferApiFiltersDTO from "@/classes/filters/OfferApiFiltersDTO";
import _ from 'lodash';
import AssetPriceFilter from "@/components/market/filters/asset-price-filter";
import AssetSortChooser from "@/components/market/filters/asset-sort-chooser";

export default {
    components: {AssetSortChooser, AssetPriceFilter, AssetCategoryFilter},
    data() {
        return {
            offerFilters: new OfferApiFiltersDTO(),
            offerSorting: {}
        }
    },
    methods: {
        emitUpdatedFilters() {
            this.$emit('filters-changed', {offerFilters: _.cloneDeep(this.offerFilters)})
        },
        emitUpdatedSorting() {
            this.$emit('sorting-changed', {offerSorting: _.cloneDeep(this.offerSorting)})
        },
        handleCategoryChange(args) {
            this.offerFilters.assetTypes = args.assetTypes;
            this.emitUpdatedFilters();
        },
        handleSortChange(args) {
            this.offerSorting = args.offerSorting;
            this.emitUpdatedSorting();
        },
        handlePriceChange(args) {
            this.offerFilters.minPrice = args.minPrice;
            this.offerFilters.maxPrice = args.maxPrice;
            this.emitUpdatedFilters();
        },
    },
}
</script>

<style lang="scss" scoped>
.row {
    border-bottom: 2px solid rgba(71, 70, 62, 1);
}
</style>
